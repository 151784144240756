import React from "react"
import Layout from "../components/layout"
import {graphql} from 'gatsby'
import {Helmet} from "react-helmet"

export const query = graphql`
  query CareersPageQuery {
    allJobs {
       edges {
         node {
           id
           url
           department
           title
           title_en
           description
           logo
         }
       }
    }
  }
`

const Careers = ({data}) => {

  const jobNodes = data.allJobs.edges;

  return (
      <Layout mainId="pageCareers" selectedMenu={"careers"}>
        <Helmet>
          <title>LUCHE GROUP | Careers</title>
          <meta name="description" content="ミッションドリブンな人、新しいことにワクワクできる人、LUCHE GROUPで私たちと一緒に未来を変えてみませんか？" />
          <link rel="canonical" href="https://luchegroup.com/careers/" />
        </Helmet>
        <div id="keyv">
          <div className="inner">
            <div className="key__image" />
            <div className="key__text">
              <h2>We are <br />
                IT Professionals</h2>
              <p className="txt--keyv bg--keyv">ミッションドリブンな人<br />
                新しいことにワクワクできる人<br />
                私たちと一緒に<br className="spDisplay" />未来を変えてみませんか？</p>
            </div>
          </div>
        </div>
        {/* / #keyv */}
        <div id="contents">
          <div className="sect--join-us sect--careers">
            <div className="inner">
              <h2 className="headline--1">Job Openings</h2>
              <p className="txt--common txt-center">現在、LUCHE GROUPで募集している職種の一覧です。</p>
              <div className="content--join">

                  {
                    jobNodes.map((jobNode) => { 
                      const job = jobNode.node
                      return (
                        <a key={job.id} href={job.url}  rel="noreferrer" target="_blank" className="content--join-item">
                          <div className="content--join-hdg">
                            <p className="content--hdg-sub">{job.department}</p>
                            <h4 className="content--hdg-title">{job.title}<span>{job.title_en}</span></h4>
                            <p className="content--hdg-text">{job.description}</p>
                          </div>
                          <div className="content--join-logo">
                            <p><img src={job.logo} alt="Giftmall" /><span className="content--join-icon"><img src="/images/common/icon_arrow.svg" alt="" /></span></p>
                          </div>
                        </a>
                      );

                    })
                  }


              </div>
              <p className="txt--center"><a className="show-all-careers hover" href="https://open.talentio.com/r/1/c/careers.luchegroup/homes/2671"rel="noreferrer" target="_blank">すべての募集職種一覧へ<img src="/images/common/icon_arrow.svg" alt=""/></a></p>
            </div>
          </div>
        </div>
      </Layout>
  );
};

export default Careers


